<template>
  <v-navigation-drawer
      app
      permanent
      :mini-variant.sync="mini"
      v-model="drawer"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          <v-img
              :src="require('../assets/logo.png')"
              contain
              height="50"
          />
        </v-list-item-title>
        <v-list-item-subtitle class="text-center">
          <span class="red--text text-sm-h6">Admin</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-btn
          icon
          @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <router-link
          v-for="item in principal"
          :key="item.title"
          :to="{ name: item.title}"
          class="link"
      >
        <v-list-item link :class="{ primary: $route.name === item.title }">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <router-link
          v-for="item in secondary"
          :key="item.title"
          :to="{ name: item.title}"
          class="link"
      >
        <v-list-item link :class="{ primary: $route.name === item.title }">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>

    <template #append>
      <v-list
          dense
          nav
      >
        <v-list-item
            @click="logoutAndRedirect"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Déconnexion
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
import { mapActions} from 'vuex'

export default {
  name: 'default',
  data() {
    return {
      principal: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Agenda', icon: 'mdi-calendar' },
        { title: 'Artistes', icon: 'mdi-account-music' },
        { title: 'Styles', icon: 'mdi-playlist-music' },
        { title: 'Albums', icon: 'mdi-image' },
        { title: 'Residents', icon: 'mdi-account-box' },
        { title: 'Message d\'accueil', icon: 'mdi-android-messages' }
      ],
      secondary: [
        { title: 'Administrateurs', icon: 'mdi-shield-check' },
      ],
      mini: true,
      drawer: true,
    }
  },
  methods: {
    ...mapActions(['logout']),
    logoutAndRedirect() {
      this.logout()
      this.$router.push({ name: 'Login' })
    }
  },
  mounted() {
    this.mini = !this.$vuetify.breakpoint.mdAndUp
  }
}
</script>

<style lang="scss">
  .link {
    text-decoration: none;
  }
</style>
